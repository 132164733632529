.coa-page {
  iframe {
    width: 100%;
    height: 800px;
    border: none;

    @media screen and (min-width: $screen-sm) {
      height: 1400px;
    }

    @media screen and (min-width: $screen-md) {
      height: 2000px;
    }
  }
}