.molecule.product {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: $spacing-800;

  .img {
    width: 100%;
    height: 365px;
    background-size: cover;
    background-position: center;
  }

  h3 {
    margin: $spacing-700 0 $spacing-base;

    a {
      color: inherit;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .dosage, .price {
    font-size: $font-size-100;
  }

  .dosage {
    font-weight: 300;
  }

  .price {
    font-weight: 600;
    margin: $spacing-300 0;
  }

  .btn {
    position: absolute;
    margin: 0 auto;
    bottom: 0;
    left: 0;
    right: 0;
    width: 130px;
  }
}