.molecule.card {
  padding: $spacing-800 $spacing-600 $spacing-1000;
  border: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .15);
  font-weight: 300;
  position: relative;
  transition: height 400ms;
  border-radius: 0;

  a {
    color: inherit;
    text-decoration: underline;
  }

  .read-more {
    position: absolute;
    bottom: $spacing-400;
    right: $spacing-600;
    cursor: pointer;
  }
}