body.coas {
  .coa-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, 100%);
    justify-content: space-between;
    margin-top: $spacing-800;
    margin-bottom: $spacing-600;

    @media screen and (min-width: $screen-sm) {
      grid-template-columns: repeat(auto-fit, 49%);
    }

    @media screen and (min-width: $screen-md) {
      grid-template-columns: repeat(auto-fit, 32%);

      &.two-entries {
        justify-content: left;
        grid-template-columns: repeat(auto-fit, 375px);

        .document {
          margin-right: 4%;
        }
      }
    }

    .document {
      left: 0;
      right: 0;
      margin: 0 auto $spacing-700;
      width: 250px;
      height: 350px;
      box-shadow: 0px 3px 5px $black-300;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      background-image: url('/assets/img/bg_document.png');
      background-size: cover;
      background-position: center;

      h3 {
        width: 100%;
        background: $gray-300;
        padding: $spacing-300 0;
      }
    }
  }
}