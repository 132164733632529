.component.popup {
    position: fixed;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    background: rgba(0, 0, 0, .5);

    &.hidden {
        display: none;
    }
    
    .inner-popup {
        display: flex;
        flex-direction: column;
        row-gap: $spacing-400;
        background: $gray-300;
        padding: $spacing-900;
        max-width: 480px;
        border-radius: $spacing-base;

        h3 {
            font-weight: 500;
        }

        p {
            font-weight: 300;
        }

        .buttons {
            display: flex;
            column-gap: $spacing-300;
        }
    }
}