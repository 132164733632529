.component.hero {
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  background-size: cover;
  background-position: center;

  @media screen and (min-width: $screen-sm) {
    height: 485px;
  }

  video {
    position: fixed;
    left: 0;
    height: 100%;
    width: 177.77777778vh; /* 100 * 16 / 9 */
    min-width: 100%;
    min-height: 56.25vw; /* 100 * 9 / 16 */
    z-index: -1;

    source {
      display: none;

      @media screen and (min-width: $screen-sm) {
        display: block;
      }
    }
  }

  .filter {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, .35);
  }
}