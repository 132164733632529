body.shop, body.merch {
  .sub-header {
    font-weight: 300;
    margin: $spacing-900 0;

    a {
      color: inherit;
      text-decoration: underline;
    }
  }
}