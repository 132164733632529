.nav-wrapper {
  height: 70px;
}

#main-nav {
  position: fixed;
  width: 100%;
  background: white;
  z-index: $z-index-nav;

  &.collapsed {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);

    .blue-block {
      height: 70px;
      box-shadow: 0 0 0;
    }

    .logo {
       margin-top: $spacing-400;
    }
  }

  .blue-block {
    width: 800px;
    height: 130px;
    background: $blue-100;
    position: absolute;
    left: -520px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
    transition: height 400ms;

    @media screen and (min-width: $screen-micro) {
      left: -460px;
    }
  }

  .logo {
    max-width: 180px;
    margin-top: $spacing-1000;
    position: relative;
    transition: margin 400ms;
  }

  .primary-nav {
    height: 70px;
  }

  ul {
    list-style: none;
    position: absolute;
    right: 0;
    top: 0;
    display: none;

    @media screen and (min-width: $screen-lg) {
      display: inline-block;
    }


    li {
      display: inline-block;
      margin-left: $spacing-400;

      &.active {
        background: $blue-100;
        color: white;
      }

      &:not(.btn):not(.cart-wrapper) {
        font-size: $font-size-100;
        font-weight: 300;
        padding: 22px 14px;
      }

      &.btn-wrapper {
        margin-top: -4px;
        margin-left: $spacing-600;
      }

      &.cart-wrapper {
        margin-left: $spacing-600;
        margin-right: $spacing-300;
        width: $spacing-800;
      }

      a {
        color: inherit;
        cursor: pointer;

        &.btn {
          color: white;
          font-weight: 300;
          display: inline-block;
          margin-top: -2px;
        }

        &:hover {
          text-decoration: none;
        }
      }

      .cart {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        top: 23px;
        cursor: pointer;

        .ico-shopping {
          width: 20px;
          height: 20px;
        }

        .cart-counter {
          font-size: $font-size-small;
          font-family: arial;
          display: inline-block;
          margin-left: $spacing-200;
          margin-top: 2px;
        }
      }
    }
  }

  .mobile {
    display: block;
    position: absolute;
    right: $spacing-600;
    top: $spacing-200;
    cursor: pointer;
    font-size: $font-size-500;

    @media screen and (min-width: $screen-xs) {
        right: 0;
      }

    .cart-wrapper {
      width: $spacing-800;
      position: relative;
      right: 55px;
      display: none;

      @media screen and (min-width: $screen-md) {
        display: block;
      }
    }

    .cart {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: absolute;
      top: 13px;
      cursor: pointer;

      .ico-shopping {
        width: 20px;
        height: 20px;
      }

      .cart-counter {
        font-size: $font-size-small;
        font-family: arial;
        display: inline-block;
        margin-left: $spacing-200;
        margin-top: 2px;
      }
    }

    @media screen and (min-width: $screen-lg) {
      display: none;
    }
  }
}

#mobile-nav {
  display: none;
  opacity: 0;
  z-index: $z-index-nav;
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, .98);
  top: 0;
  left: 0;
  text-align: center;
  transition: opacity 400ms;

  .close {
    position: absolute;
    right: $spacing-700;
    top: $spacing-600;
    font-size: $font-size-600;
    cursor: pointer;
  }

  ul {
    list-style: none;
    padding: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    li {
      margin-bottom: $spacing-400;
      font-size: $font-size-400;
      font-weight: 300;

      a:not(.btn) {
        color: $font-color;
      }

      .cart {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .ico-shopping {
          width: 20px;
          height: 20px;
        }

        .cart-counter {
          font-size: $font-size-small;
          font-family: arial;
          display: inline-block;
          margin-left: $spacing-200;
          margin-top: 2px;
        }
      }
    }
  }
}