.component.slider {
  background: $gray-300;
  height: 180px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;

  @media screen and (min-width: $screen-sm) {
    height: 200px;
  }

  @media screen and (min-width: $screen-md) {
    height: 230px;
  }

  .content {
    font-size: $font-size-small;
    max-width: 600px;
    left: 0;
    right: 0;
    margin: auto;
    height: 175px;
    display: flex;
    align-items: center;

    @media screen and (min-width: $screen-sm) {
      font-size: $font-size-base;
    }

    @media screen and (min-width: $screen-md) {
      font-size: $font-size-100;
      max-width: 750px;
    }

    .attribution {
      font-size: $font-size-small;
      margin-top: $spacing-200;

      @media screen and (min-width: $screen-md) {
        font-size: $font-size-base;
      }
    }
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    height: 100px;
    width: 30px;
    outline: $font-color;
    background-size: 100%, 100%;
    background-image: none;
    position: absolute;
    opacity: .5;
    display: none;

    @media screen and (min-width: $screen-sm) {
      display: block;
    }
  }

  .carousel-control-prev-icon {
    left: 0;
  }

  .carousel-control-next-icon {
    right: 0;
  }

  .carousel-control-next-icon:after
  {
    content: url("/assets/img/icons/ico-chevron.png");
    margin-top: $spacing-600;
    display: block;
  }

  .carousel-control-prev-icon:after {
    content: url("/assets/img/icons/ico-chevron.png");
    margin-top: $spacing-500;
    display: block;
    transform: rotate(180deg);
  }
}