@import 'variables';
@import 'type';
@import 'buttons';
@import 'nav';
@import 'footer';

// pages 
@import 'pages/four-oh-four';
@import 'pages/home';
@import 'pages/contact';
@import 'pages/shop';
@import 'pages/about';
@import 'pages/coas';
@import 'pages/goodbye';

// layouts
@import 'layouts/legal';
@import 'layouts/blog-page';
@import 'layouts/product-page';
@import 'layouts/coa-page';

// components
@import 'components/hero';
@import 'components/slider';
@import 'components/header';
@import 'components/blog-list';
@import 'components/product-list';
@import 'components/popup';

// molecules 
@import 'components/molecules/product';
@import 'components/molecules/blog-preview';
@import 'components/molecules/card';

body {
  overflow-x: hidden;
}

.anchor-link {
  position: absolute;
  margin-top: -132px;
}