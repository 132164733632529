.product-page {
  padding: 100px 0 $spacing-1000;
  text-align: center;

  @media screen and (min-width: $screen-sm) {
    text-align: initial;
    padding: 150px 0 $spacing-1000;
  }

  .mobile-header {
    margin-bottom: $spacing-600;
  }

  .image-container {
    width: 100%;
    height: 625px;
    overflow: hidden;
    position: relative;

    #slide-wrapper {
      width: 300%;
      height: 625px;
      margin-left: 0%;
      transition: margin-left 400ms;
    }
    .image {
      width: 33.33%;
      height: 625px;
      background-size: cover;
      background-position: center;
      float: left;
    }
  }

  .slide-buttons {
    text-align: center;
    margin-top: $spacing-500;

    .arrow {
      display: inline-block;
      position: relative;
      top: -2px;
      font-size: $font-size-small;
      margin: 0 $spacing-300;
      cursor: pointer;

      i {
        color: $black-300;
      }
    }

    .slide-circle {
      width: 15px;
      height: 15px;
      border: 1px solid $black-300;
      border-radius: 25px;
      display: inline-block;
      margin: 0 3px;
      cursor: pointer;

      &.active {
        background: $gray-200;
      }
    }
  }

  .price {
    margin-top: $spacing-600;

    @media screen and (min-width: $screen-sm) {
      text-align: right;
      margin-top: 0;
    } 
  }

  .dosage {
    margin-top: 20px;
  }

  .ingredients {
    margin-top: $spacing-600;
  }

  p {
    line-height: 25px;
    text-align: left;
    font-weight: 300;

    strong {
      font-weight: 600;
    }
  }

  .inputs-wrapper {
    margin: $spacing-1000 auto 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    select {
      padding: $spacing-300;
    }
  }

  .inputs {
    input {
      width: 100px;
      margin-right: $spacing-300;
      padding-left: $spacing-200;
    }
    display: flex;
    width: 275px;
  }

  .more-products {
    margin-top: 70px;
  }
}