* {
  font-family: montserrat, sans-serif;
  font-style: normal;
  color: #333;
}

h1, h2 {
  font-size: $font-size-400;

  &.thin {
    font-weight: 300;
  }
}

h2 {
  font-weight: 300;

  &.small {
    font-size: $font-size-300;
  }
}

h3 {
  font-size: $font-size-200;
  font-weight: 300;

  &.bold {
    font-weight: 600;
  }
}