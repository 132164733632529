body.home {
  .product-preview {
    background: white;
    padding: 70px 0 60px;

    h2 {
      margin-bottom: 50px;
    }

    .product-list {
      margin-bottom: 20px;
    }

    .see-more {
      font-size: $font-size-200;
      text-align: center;
      font-weight: 300;

      a {
        color: inherit;
      }

      img {
        width: 17px;
        margin-top: -2px;
      }
    }
  }
}