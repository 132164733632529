.molecule.blog-preview {
  max-width: 400px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, .16);
  transition: box-shadow 400ms;
  background: $gray-300;
  position: relative;

  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, .25);    
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }

  .img {
    width: 100%;
    height: 180px;
    background-size: cover;
    background-position: center;
  }

  .title-section {
    background: $blue-100;
    color: white;
    padding: $spacing-400 $spacing-500;

    h3 { 
      color: white; 
      font-weight: 400;
    }

    date {
      color: white;
      font-weight: 200;
    }
  }

  .summary {
    padding: $spacing-400 $spacing-500;

    p {
      font-weight: 300;
      line-height: 25px;
      margin-bottom: $spacing-800;
    }

    .more {
      font-weight: 500;
      position: absolute;
      bottom: $spacing-400;

      a {
        color: inherit;
      }

      img {
        height: 13px;
        margin-top: -1px;
      }
    }
  }
}