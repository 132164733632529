.component.blog-list {
  .blog-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, 100%);
    justify-content: space-between;
    padding-top: $spacing-800;

    article {
      left: 0;
      right: 0;
      margin: 0 auto $spacing-700;
    }

    @media screen and (min-width: $screen-sm) {
      grid-template-columns: repeat(auto-fit, 49%);
    }

    @media screen and (min-width: $screen-md) {
      grid-template-columns: repeat(auto-fit, 32%);

      &.two-entries {
        justify-content: left;
        grid-template-columns: repeat(auto-fit, 375px);

        article {
          margin-right: 4%;
        }
      }
    }
  }
}