// colors
$blue-100: #202A43;
$blue-200: #192134;
$teal-100: #59958F;
$teal-200: #477570;
$gray-100: #4C4C4C;
$gray-200: #E0E0E0;
$gray-300: #F0F0F0;
$black-100: #333;
$black-200: #555;
$black-300: #707070;

$font-color: $black-100;

// spacing
$spacing-base: 5px;
$spacing-200: $spacing-base * 2;
$spacing-300: $spacing-base * 3;
$spacing-400: $spacing-base * 4;
$spacing-500: $spacing-base * 5;
$spacing-600: $spacing-base * 6;
$spacing-700: $spacing-base * 7;
$spacing-800: $spacing-base * 8;
$spacing-900: $spacing-base * 9;
$spacing-1000: $spacing-base * 10;
$spacing-1200: $spacing-base * 12;
$spacing-1500: $spacing-base * 15;
$spacing-2000: $spacing-base * 20;

// bespoke sizes
$nav-height: 70px;

// z-indexes
$z-index-mobile-nav: 4;
$z-index-nav: 3;
$z-index-subnav: 2;
$z-index-base: 1;

// fonts
$font-family-header: rooney-web, serif;
$font-family-base: fira-sans, sans-serif;
$font-size-small: 14px;
$font-size-base: 16px;
$font-size-100: 18px;
$font-size-200: 20px;
$font-size-300: 25px;
$font-size-400: 30px;
$font-size-500: 35px;
$font-size-600: 40px;
$font-size-700: 45px;

// breakpoints
$screen-micro: 425px;
$screen-xs: 576px;
$screen-sm: 768px;
$screen-sm-max: $screen-sm - 1px;
$screen-md: 992px;
$screen-lg: 1200px;