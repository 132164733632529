.component.header {
  background: $gray-300;
  padding-bottom: $spacing-600;
  text-align: center;

  h1 {
    font-weight: 300;
    margin: 90px auto 0;
    left: 0;
    right: 0;
  }

  &.dated {
    h1 {
      margin: 110px auto 0;
    }

    date {
      margin-top: $spacing-200;
      display: inline-block;
      font-size: $font-size-100;
      font-weight: 300;
    }
  }
}