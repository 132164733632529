body.contact {
  section {
    padding-top: 100px;
    padding-bottom: 35px;
    background: $gray-300;

    @media screen and (min-width: $screen-md) {
      padding-top: 125px;
      padding-bottom: 75px;
    }

    h1 {
      margin-bottom: $spacing-500;
    }

    .information {
      margin-bottom: $spacing-800;
    }

    p {
      font-size: $font-size-200;
      font-weight: 300;
      margin-bottom: $spacing-500;

      a {
        color: inherit;
      }
    }

    form {
      text-align: center;
    }

    input, textarea {
      width: 100%;
      padding-left: $spacing-500;
      border: 1px solid $black-300;
      margin-bottom: $spacing-500;
    }

    input {
      height: $spacing-1000;
    }

    textarea {
      padding-top: $spacing-300;
      min-height: 210px;
    }

    .g-recaptcha {
      margin-bottom: $spacing-500;
    }

    input[type="submit"] {
      max-width: 240px;
      border: none;
    }
  }
}