.btn {
  background: $teal-100;
  color: white;
  font-size: $font-size-base;
  padding: $spacing-base $spacing-400;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, .16);
  cursor: pointer;

  &.active {
    background: $blue-100;
  }

  &:hover {
    color: white;
    background: $teal-200;
  }

  &.large {
    padding: $spacing-200 $spacing-600;
    font-size: $font-size-100;
    font-weight: 300;
  }

  &.secondary {
    background: white;
    color: $black-100;
  }
}