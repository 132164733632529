footer {
  background: $blue-200;
  padding: $spacing-600 0 $spacing-500;

  a {
    color: white;

    &:hover {
      color: white;
      text-decoration: underline;
    }
  }

  h5 {
    font-size: $font-size-small;
    color: white;
    text-transform: uppercase;
    letter-spacing: .4px;
    font-weight: 800;
    margin: $spacing-400 0;
  }

  p {
    color: white;
    font-weight: 200;
    font-size: $font-size-small;
    line-height: 24px;
    letter-spacing: .4px;
  }

  ul {
    list-style: none;
    padding-left: 0;
    
    li {
      color: white;
      font-size: $font-size-small;
      font-weight: 200;
      margin-bottom: $spacing-200;
    }

    &.social {
      li {
        display: inline-block;
        margin-right: $spacing-200;

        img {
          height: 25px;
        }
      }
    }
  }

  hr {
    margin: $spacing-500 0;
    border-color: $black-200;
    border-width: .5px;
  }

  .byline {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: $screen-sm) {
      flex-direction: row;
      justify-content: space-between;
    }

    span {
      color: white;
      font-size: $font-size-small;
      font-weight: 200;

      a {
        color: white;
        text-decoration: underline;
      }
    }
  }
}