.blog-page {
  .hero-img {
    width: 100%;
    height: 410px;
    background-size: cover;
    background-position: center;
    margin: 40px 0;
  }

  .content {
    background: $gray-300;
    padding: 60px 0;
    font-weight: 300;
  }

  .more-header {
    text-align: center;
    margin: $spacing-800 0 0 0;

    h3 {
      font-size: $font-size-300;
      margin: 0;
    }
  }

  .blog-list {
    margin-bottom: 70px;
  }
}