.legal-page {
  padding-bottom: 70px;
  
  .header {
    margin-bottom: 70px;
  }

  h2 {
    font-size: $font-size-300;
    margin: $spacing-700 0 $spacing-200;
  }

  p {
    font-weight: 300;
    
    a {
      color: inherit;
      text-decoration: underline;
    }
  }
}