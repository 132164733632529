body.about {
  h2 {
    font-size: $font-size-200;
    font-weight: 500;
    color: white;
    margin-bottom: $spacing-800;
    text-align: center;
  }

  .subheader {
    margin: 55px 0 60px;
    font-size: $font-size-200;
    text-align: center;
    font-weight: 200;

    @media screen and (min-width: $screen-md) {
      margin: 55px 0 0;
    }
  }

  .blue-box {
    background: $blue-100;
    z-index: -1;
    position: absolute;
  }

  .image-box {
    background-size: cover;
    background-position: center;
    position: absolute;
    z-index: -2;
  }

  .hollow-box {
    position: absolute;
    z-index: -3;
    border: 1px solid $black-300;
  }

  section {
    margin: 0;
    background: $blue-100;
    padding: 50px 0 25px;

    @media screen and (min-width: $screen-md) {
      margin: 110px 0 140px;
      padding: 0;
      background: none;
    }
  }

  .whats-hemp, .spagyric-extraction {
    @media screen and (min-width: $screen-md) {
      margin-bottom: 240px;

      h2 {
        text-align: left;
      }
    }

    .blue-box {
      height:350px; 
      width: 100%; 
      position: absolute;
      top: -50px;
      left: 0;

      @media screen and (min-width: $screen-xs) {
        width: 200%; 
        position: absolute;
        left: -200px;
      }

      @media screen and (min-width: $screen-md) {
        height: 400px; 
        width: 120%; 
        position: absolute;
        left: -180px
      }

      @media screen and (min-width: $screen-lg) {
        width: 150%; 
        position: absolute;
        left: -500px;
      }
    }

    .image-box {
      height: 330px;
      width: 100%;
      right: 0;
      top: -280px;

      @media screen and (min-width: $screen-lg) {
        top: -250px;        
      }
    }

    .hollow-box.small {
      right: 0;
      top: -305px;
      height: 165px;
      width: 100%;
    }
    .hollow-box.large {
      right: $spacing-500;
      top: -205px;
      height: 325px;
      width: 100%;
    }
  }

  .whats-full-spectrum {
    @media screen and (min-width: $screen-md) {
      margin-bottom: 240px;

      h2 {
        text-align: right;
      }
    }

    .blue-box {
      height: 350px; 
      width: 100%; 
      position: absolute;
      top: -50px;
      right: 0;

      @media screen and (min-width: $screen-xs) {
        width: 200%; 
        position: absolute;
        right: -200px;
      }

      @media screen and (min-width: $screen-md) {
        height: 400px; 
        width: 120%; 
        position: absolute;
        right: -180px
      }

      @media screen and (min-width: $screen-lg) {
        width: 150%; 
        position: absolute;
        right: -500px;
      }
    }

    .image-box {
      height: 330px;
      width: 100%;
      left: -150px;
      top: -300px;

      @media screen and (min-width: $screen-lg) {
        top: -250px;
        left: -175px;
      }
    }

    .hollow-box.small {
      left: 0;
      top: -345px;
      height: 165px;
      width: 100%;

      @media screen and (min-width: $screen-lg) {
        top: -310px;
      }
    }
    .hollow-box.large {
      left: $spacing-500;
      top: -225px;
      height: 325px;
      width: 100%;

      @media screen and (min-width: $screen-lg) {
        top: -200px;
      }
    }
  }

  .spagyric-extraction {
    padding: 50px 0 60px;

    @media screen and (min-width: $screen-md) {
      padding: 0;
    }

    .image-box {
      height: 330px;
      width: 100%;
      right: 0;
      top: -300px;

      @media screen and (min-width: $screen-lg) {
        top: -250px;        
      }
    }

    .hollow-box.small {
      right: 0;
      top: -340px;
      height: 165px;
      width: 100%;

      @media screen and (min-width: $screen-lg) {
        top: -310px;
      }
    }
    .hollow-box.large {
      right: $spacing-500;
      top: -230px;
      height: 325px;
      width: 100%;

      @media screen and (min-width: $screen-lg) {
        top: -200px;
      }
    }
  }

  .about-the-company {
    background: none;
    margin-bottom: 100px;
    margin-top: 0;
    margin-bottom: 0;

    @media screen and (min-width: $screen-md) {
      margin-top: 70px;
      margin-bottom: 70px;
    }

    h2 {
      color: $font-color;
      font-weight: 300;
      font-size: $font-size-300;
    }

    p {
      font-size: $font-size-200;
      font-weight: 300;
    }

    .team-image {
      width: 100%;
      height: 255px;
      margin-bottom: $spacing-500;
      background-size: cover;
      background-position: top;
    }
  }
}